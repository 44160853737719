.pdf-doc-wrapper .react-pdf__Page {
  border: 1px solid black;
}

html:not(.light) {
  // Override default variables before the import
  $body-bg: #111;
  $body-color: #fff;
  $primary: #a05ff1;
  $secondary: #000000;
  $card-bg: #222222;
  $card-cap-bg: #451a7c; // #503079;
  $border-color: #333;
  $modal-content-bg: #222;
  $input-bg: #222;
  $input-disabled-bg: #333;
  $input-color: #ccc;
  $input-disabled-color: #aaa;
  $input-border-color: #444;
  $table-bg: #222;
  $table-color: #ccc;
  $table-border-color: #444;
  $list-group-bg: #222;
  $link-hover-color: lighten($primary, 15%);
  $dropdown-bg: #222;
  $dropdown-color: #eee;
  $dropdown-link-color: #eee;
  $dropdown-link-hover-color: #fff;
  $dropdown-link-hover-bg: #333;

  // Import Bootstrap and its default variables
  @import "~bootstrap/scss/bootstrap.scss";

  .nav-pills,
  .nav-link {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
  }

  .tab-content {
    background-color: $card-bg;
    border: 1px solid white;
    border-radius: 0 0 0.25rem 0.25rem;
    border-top: none;
  }

  .nav-tabs .nav-item.active {
    background-color: $card-bg;
    color: white;
    border-bottom: none;
  }

  .main-header {
    z-index: 100;
  }

  a:hover {
    color: lighten($primary, 20);
  }

  button.close {
    filter: invert(1)
  }

  .modal-content {
    border: solid 2px #6c757d;
  }
  // .pdf-doc-wrapper {
  //   filter: invert(1) hue-rotate(180deg);
  // }
}

html.light {
  // Override default variables before the import
//   $body-bg: #111;
//   $body-color: #fff;
  // $primary: #a05ff1;
//   $secondary: #000000;
//   $card-bg: #222222;
  $card-cap-bg: #451a7c; // #503079;
  // $secondary: darken($primary, 20%);
  $secondary: darken(desaturate($card-cap-bg, 20%), 10%);
  $primary: lighten($card-cap-bg, 20%);
//   $border-color: #333;
//   $modal-content-bg: #222;
//   $input-bg: #222;
//   $input-disabled-bg: #333;
//   $input-color: #ccc;
//   $input-disabled-color: #aaa;
//   $input-border-color: #444;
//   $table-bg: #222;
//   $table-color: #ccc;
//   $table-border-color: #444;
//   $list-group-bg: #222;
  $link-hover-color: darken($primary, 15%);
//   $dropdown-bg: #222;
//   $dropdown-color: #eee;
//   $dropdown-link-color: #eee;
//   $dropdown-link-hover-color: #fff;
//   $dropdown-link-hover-bg: #333;

  // Import Bootstrap and its default variables
  @import "~bootstrap/scss/bootstrap.scss";

  .nav-pills,
  .nav-link {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
  }

//   .tab-content {
    // background-color: $card-bg;
    // border: 1px solid white;
    // border-radius: 0 0 0.25rem 0.25rem;
    // border-top: none;
//   }

  // .nav-tabs .nav-item.active {
  //   background-color: $card-bg;
  //   color: white;
  //   border-bottom: none;
  // }

  .btn-white {
    color: white;
  }

  .btn-white:hover {
    color: white;
  }

  .main-header {
    z-index: 100;
  }

  a:hover {
    color: darken($primary, 20%);
  }

  .bg-secondary {
    color: white;
  }

  .bg-secondary .text-muted {
    color: #eee !important;
  }

  .bg-secondary a {
    color: lighten($primary, 20%);
  }

  .bg-secondary a:hover {
    color: lighten($primary, 20%);
  }
  .navbar-dark .navbar-nav .nav-link {
    color: #ffffffaa
  }
  .navbar-dark .navbar-nav .nav-link:hover {
    color: #ffffff
  }
  .navbar-dark .navbar-nav .nav-link:active {
    color: #ffffff
  }

  .modal-content {
    border: solid 2px #333;
  }
}


.row-credit td {
  &:first-child {
    border-left: 4px solid red !important;
  }
  background-color: #ff000055;
}

.invoice-list-recipients{
  max-width: 280px;
  white-space: pre-wrap;
}